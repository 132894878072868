/*===============================================
Default css
===============================================*/
body {
   margin: 0;
   padding: 0;
   font-family: "sui-generis";
   overflow-x: hidden;
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * sui-generis:
 *   - http://typekit.com/eulas/00000000000000007735961f
 *   - http://typekit.com/eulas/000000000000000077359620
 *   - http://typekit.com/eulas/000000000000000077359625
 *   - http://typekit.com/eulas/000000000000000077359626
 *   - http://typekit.com/eulas/000000000000000077359627
 *   - http://typekit.com/eulas/000000000000000077359628
 *   - http://typekit.com/eulas/000000000000000077359629
 *   - http://typekit.com/eulas/00000000000000007735962a
 *   - http://typekit.com/eulas/00000000000000007735962b
 *   - http://typekit.com/eulas/00000000000000007735962c
 *   - http://typekit.com/eulas/00000000000000007735962d
 *   - http://typekit.com/eulas/00000000000000007735962e
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-09-19 11:42:24 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=rzd1hal&ht=tk&f=255.1273.1276.1277.1278.1279.1280.1281.1282.1283.1284.1285&a=100649833&app=typekit&e=css");

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),
      url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/d?subset_id=2&fvd=n5&v=3") format("woff"),
      url("https://use.typekit.net/af/0f82c8/00000000000000007735961f/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 500;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/l?subset_id=2&fvd=i5&v=3") format("woff2"),
      url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/d?subset_id=2&fvd=i5&v=3") format("woff"),
      url("https://use.typekit.net/af/e9bdf9/000000000000000077359620/30/a?subset_id=2&fvd=i5&v=3") format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 500;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/l?subset_id=2&fvd=n2&v=3") format("woff2"),
      url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/d?subset_id=2&fvd=n2&v=3") format("woff"),
      url("https://use.typekit.net/af/2f6b90/000000000000000077359625/30/a?subset_id=2&fvd=n2&v=3") format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 200;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/l?subset_id=2&fvd=i2&v=3") format("woff2"),
      url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/d?subset_id=2&fvd=i2&v=3") format("woff"),
      url("https://use.typekit.net/af/38ee52/000000000000000077359626/30/a?subset_id=2&fvd=i2&v=3") format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 200;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/l?subset_id=2&fvd=n3&v=3") format("woff2"),
      url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/d?subset_id=2&fvd=n3&v=3") format("woff"),
      url("https://use.typekit.net/af/3ba9ff/000000000000000077359627/30/a?subset_id=2&fvd=n3&v=3") format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 300;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/l?subset_id=2&fvd=i3&v=3") format("woff2"),
      url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/d?subset_id=2&fvd=i3&v=3") format("woff"),
      url("https://use.typekit.net/af/f83f22/000000000000000077359628/30/a?subset_id=2&fvd=i3&v=3") format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 300;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
         format("woff2"),
      url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
         format("woff"),
      url("https://use.typekit.net/af/4c997e/000000000000000077359629/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
         format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 400;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
         format("woff2"),
      url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
         format("woff"),
      url("https://use.typekit.net/af/da5775/00000000000000007735962a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
         format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 400;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
         format("woff2"),
      url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
         format("woff"),
      url("https://use.typekit.net/af/a2456e/00000000000000007735962b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
         format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 700;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
         format("woff2"),
      url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
         format("woff"),
      url("https://use.typekit.net/af/0afbfd/00000000000000007735962c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
         format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 700;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/l?subset_id=2&fvd=n9&v=3") format("woff2"),
      url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/d?subset_id=2&fvd=n9&v=3") format("woff"),
      url("https://use.typekit.net/af/0e2b9f/00000000000000007735962d/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
   font-display: auto;
   font-style: normal;
   font-weight: 900;
   font-stretch: normal;
}

@font-face {
   font-family: "sui-generis";
   src: url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/l?subset_id=2&fvd=i9&v=3") format("woff2"),
      url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/d?subset_id=2&fvd=i9&v=3") format("woff"),
      url("https://use.typekit.net/af/34ad61/00000000000000007735962e/30/a?subset_id=2&fvd=i9&v=3") format("opentype");
   font-display: auto;
   font-style: italic;
   font-weight: 900;
   font-stretch: normal;
}

.tk-sui-generis {
   font-family: "sui-generis", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
   margin: 0;
   padding: 0;
}

ul {
   margin: 0;
   padding: 0;
   list-style: none;
}

a {
   margin: 0;
   text-decoration: none;
}

a:hover {
   text-decoration: none;
}

.section_padding {
   padding: 100px 0;
}

.section_heading {
   text-align: center;
}

.section_heading h2 {
   position: relative;
}

.section_heading h2:after {
   position: absolute;
   content: "";
}

.section_heading h2:before {
   position: absolute;
   content: "";
}

.section_padding2 {
   padding: 150px 0;
}

/* menu css */
#menuToggle {
   display: block;
   position: relative;
   z-index: 1;
   -webkit-user-select: none;
   user-select: none;
   margin-top: 5px;
}

#menuToggle a {
   text-decoration: none;
   color: #fff;
   transition: color 0.3s ease;
}

#menuToggle a:hover {
   color: #e8e8e8;
   text-shadow: 5px 5px 13px #10d83a;
   -webkit-text-stroke: 0.4px #10d83a;
   text-shadow: 0 0 7px #10d83a, 0 0 10px #10d83a, 0 0 21px #10d83a, 0 0 42px #10d83a, 0 0 82px #10d83a, 0 0 92px #10d83a, 0 0 102px #10d83a,
      0 0 151px #10d83a;
}

#menuToggle input {
   display: block;
   width: 40px;
   height: 32px;
   position: fixed;
   top: 18px;
   cursor: pointer;
   opacity: 0;
   z-index: 2;
   -webkit-touch-callout: none;
   box-shadow: 0 9px 24px rgb(0, 231, 8);
}
#menu img {
   width: 37x;
   height: 37px;
   object-fit: cover;
   margin-top: -97px;
   margin-left: 261px;
   position: fixed;
}
#menuToggle span {
   display: block;
   width: 39px;
   height: 2px;
   margin-bottom: 9px;
   position: relative;
   background: #fff;
   border-radius: 3px;
   z-index: 1;
   transform-origin: 4px 0px;
   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
   background: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
   opacity: 0.55s ease;
   box-shadow: 0px 0px 1px 1.5px rgb(16, 216, 58);
}

#menuToggle span:first-child {
   transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
   transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, -1px);
   background: #fff;
   opacity: 0;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
   transform: rotate(-45deg) translate(0, -1px);
}

#menu {
   position: fixed;
   width: 400px;
   margin: 0;
   padding: 30px;

   background: #197e38;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   transform-origin: 0% 0%;
   transform: translate(100%, 0);

   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
   top: 0px;
   height: 100vh;
   padding-top: 114px;
   overflow: auto;
}

#menu li {
   padding: 11px 0;
   font-size: 18.2px;
   font-weight: 500;
   text-transform: capitalize;
}

#menuToggle input:checked ~ ul {
   transform: translate(-71%, 0);
}
/* #menuToggle input[type=checkbox]:checked{
  position: fixed;
  right: -93%;
  top: 14px;
  width: auto;
  opacity: 1;

  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");

} */

/* End Menu css  */

.container-fluid {
   max-width: 96% !important;
}

/*=================================================================
				Start Main css
=================================================================*/
.header_area_design {
   background: #100010;
   box-shadow: 0 11px 27px rgb(49, 255, 100);
   padding: 6px 0;
   position: relative;
   width: 100%;
   z-index: 111;
   top: 0;
   left: 0;
   position: sticky;
}

.header_all {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.logo img {
   height: 59px;
   width: auto;
}

.menu_option {
   display: flex;
   align-items: center;
}

.menu_option h1 {
   color: #fff;
   margin-right: 32px;
   font-size: 32px;
   font-weight: 500;
   text-transform: uppercase;
   letter-spacing: 1.1px;
   position: relative;
   z-index: 99;
}

.banner_area_desing {
   padding-top: 153px;
   padding-bottom: 170px;
   text-align: center;
   position: relative;
   margin-top: 0;
}

.banner_all_content h1 {
   font-size: 52px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
}

.book_now {
   display: inline-block;
   position: relative;
}

.book_now a {
   color: #fff;
   text-transform: uppercase;
   font-weight: 500;
   letter-spacing: 1px;
   background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(33, 148, 64, 0.7) 100%);
   padding: 7px 37px;
   display: inline-block;
   font-size: 41px;
   position: relative;
}

.book_now a::after,
.book_now a::before {
   content: "";
   position: absolute;
   height: 100%;
   width: 6px;
   background: #fff;
   top: 0;
   border: 1.5px solid #10d83a;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}

.book_now a::after {
   right: 0;
}

.book_now a::before {
   left: 0;
}

.book_now:after,
.book_now:before {
   content: "";
   position: absolute;
   height: 5px;
   width: 98px;
   border: 1px solid #10d83a;
   background-color: #fff;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}

.book_now:after {
   right: 0;
   top: 0;
}

.book_now:before {
   left: 0;
   bottom: 0;
}

.about_area_design {
   padding-bottom: 190px;
   position: relative;
   z-index: 1;
   box-shadow: 0 11px 24px 44px rgb(23, 10, 40);
}

.about_area_design:after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: -1;
   background: linear-gradient(to top, rgb(113, 21, 8, 0), rgb(23, 10, 40, 1));
   -webkit-box-shadow: inset 0 112px 20px -15px #170a28;
   box-shadow: inset 0 112px 20px -15px #170a28;
}

.heading h2 {
   color: #fff;
   font-size: 36px;
   padding: 48px 0;
   text-align: center;
   font-weight: 550;
}

.heading p {
   font-size: 16px;
   color: #fff;
   text-align: center;
   max-width: 955px;
   margin: 0 auto;
   font-size: 15;
   font-weight: 400;
   margin-top: 47px;
   width: 100%;
}

.all_item_area {
   display: flex;
   justify-content: space-around;
   margin-top: 70px;
   margin-bottom: 70px;
}

.s_img img {
   height: 65px;
}

.single_item {
   text-align: center;
}

.single_item p {
   color: #fff;
   margin-top: 24px;
   font-size: 15px;
   padding-left: 2vw;
   padding-right: 2vw;
}

.video_area {
   max-width: 950px;
   margin: 0 auto;
   min-height: 500px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.video_area button {
   background: transparent;
   outline: none;
   border: none;
}

.btn-primary:hover {
   color: #fff;
   background-color: transparent;
   border-color: transparent;
   opacity: 0.7;
}

.video_area img {
   width: 74px;
}

/* Modal css  */
.modal-dialog {
   max-width: 800px;
   margin: 30px auto;
}

.modal-body {
   position: relative;
   padding: 0px;
}

.close {
   position: absolute;
   right: -30px;
   top: 0;
   z-index: 999;
   font-size: 2rem;
   font-weight: normal;
   color: #fff;
   opacity: 1;
}

.btn-primary.focus,
.btn-primary:focus {
   box-shadow: none;
}

.shap {
   position: absolute;
   width: 45%;
}

.shap img {
   width: 100%;
}

.shap1 {
   right: 0;
   top: -0.8%;
}

.shap2 {
   left: -7.5px;
   top: -4.8%;
}

.heading-aboutUs {
   /*margin-top: -16px !important;*/
}

.heading-aboutUs h2 {
   padding-top: 30px;
}

/* End Modal css  */
.banner_all_content2 {
   text-align: center;
}

.booking_area_design {
   padding: 190px 0;
   margin-top: -82px;
   position: relative;
   padding-bottom: 140px;
   z-index: 1;
}

.banner_all_content2 h1 {
   text-shadow: none;
   -webkit-text-stroke: transparent;
}

.games_area_design {
   background: #100819;
}

.all_game {
   display: flex;
   flex-wrap: wrap;
}

.single_game {
   flex: 0 0 50%;
   max-width: 50%;
   position: relative;
}

.single_game img {
   height: 100%;
   width: 100%;
}

.booked {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

.cir {
   text-align: center;
   padding: 30px;
}

.cir img {
   height: 150px;
}

.shap-img {
   position: absolute;
   bottom: -14%;
   width: 102.3%;
   z-index: 1;
}

.shapping_22 {
   position: absolute;
   top: -100px;
   left: 10%;
   z-index: 1;
}

.shapping_22 img {
   width: 289px;
   height: auto;
   margin-left: -30px;
}

.as_img {
   position: absolute;
   top: -25px;
   left: -109px;
   z-index: 1;
}

.as_img img {
   width: 61%;
}

.all_dot {
   display: flex;
   justify-content: center;
   bottom: -14px;
   position: absolute;
   right: 10%;
}

.all_dot .d_item {
   width: 5px;
   height: 25px;
   background-color: #fff;
   margin: 0 10px;
   display: block;
   box-shadow: 0px 0px 9px 6px rgb(16, 216, 58);
   transform: rotate(21deg);
}

.divider_img {
   height: 5px;
   width: 100%;
   /* box-shadow: 0px 5px 10px -7px rgb(16, 216, 58); */
   background-color: #fff;
   border: 1px solid rgb(16, 216, 58);
   box-shadow: 0px 0px 11px 3px rgb(16 216 58);
   position: absolute;
   z-index: 10;
}

.footer_area_design {
   padding: 100px 0;
   background: #000;
   padding-bottom: 60px;
}

.single_de {
   display: flex;
   margin: 18px 0;
}

.c_img {
   max-width: 30px;
   flex: 0 0 30px;
   text-align: center;
   margin-right: 10px;
}

.c_img i {
   font-size: 20px;
   color: #fff;
}
.c_img img {
   height: 25px;
   align-self: center;
}

.fa.fa-mobile {
   font-size: 25px;
}

.fa.fa-map-marker {
   font-size: 28px;
}

.single_de p,
.single_de p a {
   color: #fff;
   line-height: normal;
   font-size: 17px;
   font-weight: 300;
   align-self: center;
}

.c_title h2 {
   color: #fff;
   font-weight: 700;
   font-size: 36px;
}

.single_de p a {
   -webkit-text-stroke: 1px #0fd83942;
}

.map {
   position: relative;
}

.btn_booking {
   margin: 50px 0;
}

.map_con p {
   color: #fff;
   line-height: normal;
   font-size: 17px;
   font-weight: 300;
   margin: 15px 0;
}

.map_con p span {
   -webkit-text-stroke: 1px #0fd83942;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: rgba(15, 216, 57, 0.26);
}

.all_icons a {
   color: #fff;
   font-size: 30px;
   margin-right: 17px;
   transition: 0.3s;
}

.all_icons a:hover {
   opacity: 0.8;
}

.all_icons img {
   height: 25px;
}

.copyright p,
.copyright p a {
   color: rgb(15, 216, 57, 0.7);
   font-weight: 300;
   font-size: 11px;
}

.copyright p a {
   margin: 0 10px;
}

.copyright {
   margin-top: 55px;
}

/* all new css  */
.rooms_default_area {
   position: relative;
   z-index: 11;
}

.default_video img {
   width: 100%;
}

.default_top {
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.de_left img {
   width: 100px;
}

.de_content {
   margin-top: 52px;
}

.de_content p {
   color: #fff;
   text-align: left;
}

.text {
   position: relative;
}

.img img {
   height: 116px;
}

.de_left img {
   width: 129px;
   margin-right: 25px;
}

.d_text_img {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-left: 24px;
}

.d_text_img img {
   height: 64px;
}

.de_position {
   position: absolute;
   bottom: -42px;
   left: -8%;
   height: 97%;
   width: 80%;
}

.de_position img {
   width: 100%;
}

.shap_position {
   position: absolute;
   left: -12%;
   right: 0;
   /* width: 111%; */
   height: 63px;
   transform: rotate(1deg);
   bottom: -3px;
}

.shap_position img {
   width: 100%;
   height: 100%;
}

/* End all new css  */

.m_video iframe {
   width: 100%;
   min-height: 400px;
   margin-bottom: 0;
}

.m_video {
   width: 100%;
   height: 400px;
}

.overlay {
   position: relative;
   z-index: 1;
}

.overlay::after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.5);
   z-index: -1;
}

.margin_class {
   margin-top: -51px;
   padding-top: 171px;
}

.default_top2 {
   justify-content: flex-start;
}

.default_top2 .d_text_img img {
   height: 111px;
   margin-bottom: 23px;
}

.default_top2 .d_text_img {
   padding-left: 0;
}

.default_top2 .de_left img {
   width: 129px;
   margin-right: 0;
   margin-left: 47px;
}

.default_top2 .de_position {
   position: absolute;
   bottom: -42px;
   left: auto;
   right: -5%;
   height: 97%;
   width: 61%;
}

.rooms_default_area3 {
   margin-top: -56px;
}

.create_shap {
   position: absolute;
   width: 100%;
   height: 4px;
   border: 1px solid #10d83a;
   background-color: #fff;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}

.create_shap {
   position: absolute;
   width: 100%;
   height: 5px;
   border: 1px solid #b636b6;
   background-color: rgba(255, 255, 255, 0.9);
   box-shadow: 0px 0px 15px 6px rgb(195, 63, 195);
   top: 24px;
   transform: rotate(-2deg);
   z-index: 999999;
}

.text3 img {
   height: 70px !important;
   margin-bottom: 0 !important;
}

.shap_position.shap_position_last {
   transform: rotate(-1deg);
   bottom: -29px;
}

/* all room page css  */
.room_page_hero_area {
   position: relative;
   background-size: cover !important;
}

.room_page_hero_area::after {
   content: "";
   position: absolute;
   background: url(../images/games/bg_overlay.png) no-repeat scroll 0 0 / 100% 100%;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: -1;
   opacity: 0.5;
}

.r_top h1 {
   font-size: 56px;
   color: #fff;
   font-style: italic;
   margin-bottom: 18px;
}

.r_top p {
   color: #fff;
   margin-bottom: 50px;
   max-width: 800px;
   width: 100%;
}

.t_search {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.single_g_search {
   position: relative;
}

.single_g_search.single_g_search1 {
   flex: 0 0 68%;
}

.single_g_search.single_g_search2 {
   flex: 0 0 32%;
}

.single_g_search input {
   font-family: "sui-generis";
   width: 100%;
   padding: 16px;
   background: transparent;
   color: #fff;
   border: none;
   outline: none;
   height: 87px;
   margin-left: -15px;
   font-size: 16px;
   font-weight: 400;
}

.single_g_search.single_g_search1 input {
   background: url(../images/games/input.png) no-repeat scroll 0 0 / 100% 100%;
   padding-left: 84px;
   width: 100%;
}

.single_g_search input::placeholder {
   color: #fff;
   opacity: 1;
}

.single_g_search.single_g_search2 input[type="submit"] {
   background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
   width: 109%;
   cursor: pointer;
}

.input_position {
   position: absolute;
   width: 20px;
   top: 31px;
   left: 29px;
}

.input_position img {
   width: 93%;
   height: 93%;
}

.menu_with_content {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.tab_menu {
   flex: 0 0 32%;
   max-width: 32%;
}

.tab_menu ul {
   display: block;
   border-bottom: none;
}

.tab_menu ul li a {
   background: url(../images/games/btn_shap.png) no-repeat scroll 0 0 / 100% 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   height: auto;
   color: #fff;
   font-size: 18px;
   font-weight: 400;
   padding: 0 30px;
   min-height: 60px;
}

.card-header {
   /*! background: url(../images/games/btn_shap.png) no-repeat scroll 0 0 / 100% 100%; */
   opacity: 1;
   height: 60px;
   color: #fff;
   font-size: 18px;
   font-weight: 400;
   /*! padding: 0 30px; */
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.card {
   background: transparent !important;
}

.all_tabs_default {
   margin-top: 36px;
}

.all_content {
   flex: 0 0 68%;
   max-width: 68%;
}

.ranking_divs_area {
   width: 103%;
}

.accordion .card-header:after {
   font-family: "FontAwesome";
   content: "\f068";
   float: right;
}

.accordion .card-header.collapsed:after {
   /* symbol for "collapsed" panels */
   content: "\f067";
}

.tab_menu li .active {
   background: url(../images/games/active_de.png);
   background-repeat: no-repeat;
   background-size: 104% 104%;
   background-position: 58% 0;
}

.ranking_all {
   background: url(../images/games/bg2.png);
   background-size: 100% 100%;
   background-position: 0 0;
   margin-left: 25px;
}

.rank {
   background: url(../images/games/gp.png) no-repeat scroll 0 0 / 100% 100%;
   padding: 20px 18px;
}

.ranking_items {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.ranking_all {
   background: url(../images/games/bg2.png) no-repeat;
   background-size: 100% 100%;
   background-position: 0 0;
   padding: 33px;
   padding-bottom: 50px;
   height: 392px;
   overflow: hidden;
}

.ranking_items {
   height: 292px;
   overflow-y: scroll;
   scrollbar-width: thin;
}

.rank.rank_1 {
   max-width: 15%;
   flex: 0 0 15%;
   text-align: center;
}

.rank li {
   font-size: 15px;
   font-weight: 700;
   color: #fff;
   padding-bottom: 4px;
}

.rank.rank_2 {
   flex: 0 0 49%;
   max-width: 49%;
}

.rank.rank_3 {
   flex: 0 0 30%;
   max-width: 30%;
}

.ranking_all h2 {
   font-size: 25.5px;
   text-align: center;
   color: #fff;
   font-weight: 600;
   margin-bottom: 16px;
}

/* scroll bar css  */
.ranking_items::-webkit-scrollbar {
   width: 3px;
   height: 6px;
   background-color: #aaa;
}

/* Add a thumb */
.ranking_items::-webkit-scrollbar-thumb {
   background: #fff;
}

.find_tab ul {
   display: flex;
   flex-wrap: wrap;
}

.find_tab ul li {
   flex: 0 0 50%;
   max-width: 50%;
}

.find_tab ul li a {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 63px;
   color: #fff;
   font-size: 26.5px;
   font-weight: 700;
   padding: 0 30px;
   text-transform: uppercase;
}

.nav-tabs {
   border-bottom: none !important;
}

.find_tab ul li:first-child a {
   background: url(../images/games/btn_shap.png) no-repeat scroll 0 0 / 100% 100%;
}

.find_tab ul li:last-child a {
   background: url(../images/games/bg3.png) no-repeat scroll 0 0 / 100% 100%;
}

.find_tab {
   margin-top: 37px;
   margin-bottom: 45px;
}

.find_tab ul li:first-child a.active {
   background: url(../images/games/input.png);
   background-repeat: no-repeat;
   background-size: 104.7% 145%;
   background-position: 53% 50%;
   box-shadow: 0 0 10px 5px rgb(2 205 62) !important;

   /* background:url(../images/checkScores/selected.png);
  background-repeat: no-repeat;
  background-size: 112.4% 147%;
  background-position: 51% 48%; */
}

.find_tab ul li:last-child a.active {
   background: url(../images/games/input.png);
   background-repeat: no-repeat;
   background-size: 102% 127%;
   background-position: 84% 50%;
}

.tab-pane:first-child .tab_menu li .active {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   background: linear-gradient(to top, #01ab34, #00d942);
}

.card-title {
   margin-bottom: 0;
}

.acc_top {
   background-size: 100% 100% !important;
   background-position: 0 0 !important;
   width: 105%;
   margin-left: -1%;
   margin-top: -2%;
   padding: 42px;
}

.acc_top h2 {
   color: #fff;
   font-size: 24px;
   font-weight: 600;
}

.flex_acc {
   display: flex;
   align-items: center;
   margin-top: 20px;
}

.a_logo {
   margin-right: 7px;
}

.a_logo img {
   width: 84px;
}

.a_con h4 {
   color: #fff;
   font-size: 25px;
}

.a_con h5 {
   color: #fff;
   font-size: 17px;
}

.a_bottom {
   margin-top: 23px;
}

.a_bottom p {
   color: #fff;
   font-size: 16px;
   margin: 9px 0;
}

.first_c {
   width: 200px;
   display: inline-block;
}

.a_single_a {
   background-size: 115% 100% !important;
   background-repeat: no-repeat !important;
   padding: 40px 30px 40px 30px;
   width: 102%;
   /* min-height: 400px; */
}
.a_single_a_color {
   background-size: 115% 77% !important;
}
.a_single_a_color_next {
   background-size: 100% 77% !important;
}

.a_single_top {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.ac_con {
   background-size: 100% 100% !important;
   display: flex;
   align-items: center;
   justify-content: center;
   max-width: 100px;
   height: 84px;
   width: 100%;
   color: #fff;
   font-size: 23px;
   font-weight: 700;
}

.a_right {
   display: flex;
}

.ar_text {
   font-size: 24px;
   font-weight: 700;
   text-align: right;
   line-height: normal;
   font-style: italic;
}

.a_single_a h3 {
   color: #fff;
   font-size: 17.5px;
   margin-bottom: 11px;
   font-weight: 400;
   margin-top: -2%;
   margin-left: 3%;
}

.ar_icon img {
   width: 94px;
   margin-top: -7px;
}

.p_color {
   margin-top: 25px;
   margin-left: 2.5%;
}

.p_color p {
   font-size: 30px;
}

.p_color p {
   font-size: 18px;
   font-weight: 700;
}

.p_color a {
   background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
   width: 109%;
   cursor: pointer;
   font-family: "sui-generis";
   width: 100%;
   padding: 16px;
   background: transparent;
   color: #fff;
   border: none;
   outline: none;
   height: 95px;
   margin-left: -15px;
   font-size: 18px;
   font-weight: 400;
}

.p_color a {
   background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
   cursor: pointer;
   font-family: "sui-generis";
   padding: 16px;
   color: #fff;
   border: none;
   outline: none;
   margin-left: -15px;
   font-size: 15px;
   font-weight: 400;
   max-width: 489px;
   height: 91px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 700;
   margin-top: 24px;
}

.ar_text h2 {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   font-size: 30px;
   color: #fff;
   font-weight: 600;
}

.ar_text h2 img {
   width: 24px;
   margin-right: 5px;
   margin-top: -9px;
}
.ar_text p {
   font-size: 17.5px;
}

.shap_position.shap_position_last2 {
   position: relative;
   margin-bottom: -68px;
   transform: rotate(-0.8deg);
}

.card-header {
   padding: 0;
   width: 100%;
   border: none !important;
   background: none !important;
}

#accordion {
   width: 100%;
   border: none !important;
}

.card.mb-0 {
   width: 100% !important;
   border: none !important;
}

.card-body {
   -ms-flex: 1 1 auto;
   flex: 1 1 auto;
   padding: 0 0 10px 0;
}

.position_background {
   position: absolute;
   width: 100%;
   max-height: 600px;
   top: 0;
   left: 0;
   z-index: -1;
}

.position_background img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.position_background::after {
   content: "";
   box-shadow: 42px 43px 22px rgba(0, 0, 0, 0.5);
   position: absolute;
   bottom: -9%;
   width: 100%;
   height: 100px;
   left: 0;
}

.nav.nav-tabs.games_tab a {
   font-size: 14px;
   min-height: auto;
   padding-top: 10px;
   padding-bottom: 10px;
   color: rgba(255, 255, 255, 0.9);
   border: none !important;
   box-shadow: none !important;
}

.nav.nav-tabs.games_tab a img {
   width: 25px;
   margin-right: 10px;
}

.card-body {
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   padding: 0;
   border: 2px solid #fff;
   border-top: none !important;
   background: rgb(0, 191, 58) !important;
   margin-top: 0px;
   padding-top: 12px;
   padding-bottom: 10px;
}

.card-body a {
   background: none !important;
}

.games_content {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   background: linear-gradient(to top, rgb(113, 21, 8, 0.5), rgb(0, 216, 65, 0.5));
   margin-left: 30px;
}

.top_rank_con {
   display: flex;
   padding: 30px;
   align-items: center;
   justify-content: space-between;
}
.top_rank_left {
   flex: 2;
}
.top_rank_right2 {
   flex: 2;
}
.top_rank_left h3 {
   font-size: 20px;
   color: #fff;
   margin-bottom: 4px;
   font-weight: 700;
}

.top_rank_left h5 {
   color: #fff;
   font-size: 17px;
}

.logo_rank img {
   width: 60px;
   margin-top: -7px;
}

.top_rank_right {
   display: flex;
}

.logo_g2 {
   margin-left: 10px;
}

.logo_g2 p {
   color: #fff;
   font-size: 15px;
}

.soon {
   padding: 30px;
}

.game_title {
   padding: 0 30px;
   text-align: center;
   color: #fff;
   border-bottom: 2px solid #fff;
   padding-bottom: 13px;
}

.nav.nav-tabs.games_in_tab {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}

.nav.nav-tabs.games_in_tab li {
   display: flex;
   flex: 1;
   text-align: center;
}

.nav.nav-tabs.games_in_tab li a {
   color: #fff;
   border-left: 2px solid #fff;
   border-bottom: 2px solid #fff;
   display: block;
   padding: 13px 0;
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}
#g1 > div.games_page_inner > ul > li:nth-child(1) > a {
   border-left: none;
}

.nav.nav-tabs.games_in_tab a.active {
   border-bottom: none;
}

.nav.nav-tabs.games_in_tab li:first-child a.active {
   border-left: none;
}

.games_tab_pannel_content {
   padding: 6px;
}

.games_tab_pannel_content th,
.games_tab_pannel_content td {
   color: #fff;
   border: none;
}

.table thead th {
   border-bottom: none !important;
}

.find_tab ul li:last-child a.active {
   box-shadow: 0 0 10px 5px rgb(219, 27, 23);
   border: 2px solid #fff;
   /* background: url(../images/games/bg3.png) no-repeat scroll 0 0 / 100% 100%; */
   background: linear-gradient(to top, #8d2c35, #f90808) !important;
}

.tab-pane:last-child .tab_menu li .active {
   box-shadow: 0 0 10px 5px rgb(219, 27, 23);
   border: 2px solid #fff;
   background: url(../images/games/bg3.png) no-repeat scroll 0 0 / 100% 100%;
}

.tab-pane:last-child .card-body {
   box-shadow: 0 0 10px 5px rgb(219, 27, 23);
   border: 2px solid #fff;
   background: url(../images/games/bg3.png) no-repeat scroll 0 0 / 100% 100%;
}

.account_summary_2 {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(219, 27, 23);
   background: rgba(219, 27, 23, 0.3);
   margin-left: 30px;
}

.account_s_2_top {
   padding: 30px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.account_s_2_top h2 {
   color: #fff;
   font-size: 21px;
}

.account_s_2_top p {
   color: #fff;
   font-size: 15px;
   text-align: center;
}

.tab-pane:last-child .games_content {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(219, 27, 23);
   background: rgba(219, 27, 23, 0.3);
}

/* End Room page css  */
.fr_title {
   margin-bottom: 40px;
   padding-bottom: 40px;
   text-align: center;
}
.fr_title h1 {
   font-size: 61px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   text-align: center;
}
.fr_title p {
   color: #fff;
   font-size: 27px;
   font-weight: 650;
   max-width: 500px;
   text-align: center;
   margin: 0 auto;
   margin-top: 55px;
   margin-bottom: 40px;
   font-style: italic;
}
.single_fra h3 {
   color: #00e646;
   margin-bottom: 20px;
   font-size: 24px;
   font-weight: 600;
   font-style: italic;
}
.single_fra p {
   color: #cfd1d0;
   font-size: 16px;
   font-weight: 400;
}
.contact_us_more {
   position: relative;
   z-index: 1;
}

.contact_us_more h3 {
   color: #00e646;
   font-size: 33px;
   max-width: 500px;
   text-align: center;
   margin: 0 auto;
   margin-top: 122px;
   font-style: italic;
   font-weight: 600;
}
.shapping_22.fra2 {
   left: 0;
   top: 58px;
}

.single_fra_input label {
   color: #fff;
   font-size: 20px;
   margin-top: 15px;
}
.single_fra_input input,
.single_fra_input textarea {
   width: 100%;
   outline: none;
   background: transparent;
   font-size: 16px;
   font-weight: 400;
   color: #fff;
   border: 1px solid #839d71 !important;
   padding: 15px 22px;
   height: 60px;
   font-family: "sui-generis";
}

.single_fra_input input {
   border: 0 !important;
}
.withBorder input {
   border: 1px solid #839d71 !important;
}

.single_fra_input textarea {
   height: 250px;
}
.fra_mid {
   margin-bottom: 100px;
}

.single_fra_input input::placeholder,
.single_fra_input textarea::placeholder {
   color: #839d71;
   opacity: 1;
}
.single_fra_input2 {
   display: flex;
   justify-content: center;
}
.single_fra_input2 input {
   font-family: "sui-generis";
   max-width: 400px;
   width: 100%;
   padding: 16px;
   background: transparent;
   color: #fff;
   border: none;
   outline: none;
   height: 95px;
   margin-left: -15px;
   font-size: 25px;
   font-weight: 400;
   background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
   cursor: pointer;
   text-transform: uppercase;
   margin: auto;
}

.p_line {
   width: 200px;
   height: 3px;
   background-color: #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   position: absolute;
   border-radius: 50px;
}
.p_line.p_line1 {
   right: 0;
   bottom: 62px;
}
.p_line.p_line2 {
   right: 10%;
   bottom: 79px;
}
.p_line.p_line2 {
   right: 10%;
}

.login_div {
   display: flex;
   justify-content: space-between;
}
.fr_title.log_in {
   margin-bottom: 0;
   padding-bottom: 0;
   text-align: left;
}
.fr_title.log_in h1 {
   text-align: left;
}
.login_single.single_fra_input input {
   /* background-image: linear-gradient(to top, rgba(33, 148, 64, 0.2), rgb(33, 148, 64, 0.2) 100%);
	position: relative;
	box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
	border: 2px solid #bee7c1;
  -webkit-box-shadow: inset 0 0 15px rgb(16, 216, 58), 0 0 25px rgb(16, 216, 58);
  -moz-box-shadow: inset 0 0 15px rgb(16, 216, 58), 0 0 25px rgb(16, 216, 58);
  box-shadow: inset 0 0 15px rgb(16, 216, 58), 0 0 25px rgb(16, 216, 58);
  border-radius: 2px; */
   height: 58px;
   background: url(../images/games/input.png) no-repeat scroll 0 / 100% 117%;
   width: 100%;
   font-size: 21px;
   padding-left: 34px;
   font-style: italic;
}
.login_single.single_fra_input input::placeholder {
   color: #cff3b1;
   font-size: 20px;
}
.very_long_input input {
   background: url(../images/games/input.png) no-repeat scroll -12% / 99% 117% !important;
   width: 104% !important;
   margin-left: -1.5% !important;
   padding-left: 48px !important;
}

.login_single a {
   color: #fff;
   font-size: 20px;
   padding: 14px 0;
   display: block;
}
.login_btn input {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   background: linear-gradient(to top, #01ab34, #00d942);
}
.login_single.login_btn input {
   font-family: "sui-generis";
   color: #fff;
   width: 194px;
   height: 64px;
   font-size: 35px;
   font-weight: 700;
   cursor: pointer;
}
.register {
   margin-top: 32px;
   margin-left: 0;
}
.login_single.login_btn.register_btn input {
   width: 320px;
}
.login_single.login_btn.register_btn {
   margin-bottom: 99px;
}
.login_single.single_fra_input.single_fra_input_register {
   margin-bottom: 31px;
}
.login_single.login_btn.register_btn.register_btn_sub {
   margin-bottom: 0;
   display: flex;
   justify-content: center;
   margin-top: 25px;
}

.login_single.single_fra_input input::placeholder {
   font-style: italic;
}
.home-title {
   position: relative;
   z-index: 2;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.home-video-container {
   position: relative;
   width: 100vw;
   height: 100vh;
}
.home-video-container::before {
   content: "";
   position: absolute;
   background-image: url("../../assets/images/home-video/dotsbg.webp");
   border-radius: 5px;
   inset: 0;
   z-index: 1;
}
.home-video {
   position: absolute;
   inset: 0;
   width: 100vw;
   height: 100vh;
   object-fit: cover;
}
.getEnergized_title {
   max-width: 1140px;
   margin: auto;
}
.play-video {
   /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
   display: flex;
   flex-direction: column;
   align-items: center;
   z-index: 2;
}

.play-video-container {
   cursor: pointer;
   margin-top: 20px;
   -webkit-animation-name: play-video-container;
   animation-name: play-video-container;
   -webkit-animation-duration: 2s;
   animation-duration: 2s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
}
@-webkit-keyframes play-video-container {
   0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
      opacity: 0.3;
   }
   100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
   }
}
@keyframes play-video-container {
   0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
      opacity: 0.3;
   }
   100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
   }
}
.play-trailer-text {
   -webkit-animation-name: play-trailer-text;
   animation-name: play-trailer-text;
   -webkit-animation-duration: 5s;
   animation-duration: 5s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
}
@-webkit-keyframes play-trailer-text {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
@keyframes play-trailer-text {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.play-video-container p {
   font-size: 18px;
   color: #fff;
   font-weight: 400;
}
.play-video img {
   margin-top: 15px;
   width: 75px;
}

.bg-1 {
   /* background: url("../../assets/images/home-video/one-page-big.jpg") no-repeat scroll 0 0 / 100% 100%; */
   background-color: #000;
}
.bg-2 {
   background: url("../../assets/images/home/hero2.webp") no-repeat scroll 0 0 / 100% 100%;
}
.bg-3 {
   background: url("../../assets/images/home/Energize_Experience.webp") no-repeat scroll 0 0 / 100% 100%;
}
.bg-1-room {
   background: url("../../assets/images/room/1/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-2-room {
   background: url("../../assets/images/room/2/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-3-room {
   background: url("../../assets/images/room/3/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-4-room {
   background: url("../../assets/images/room/4/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-5-room {
   background: url("../../assets/images/room/5/bg1-min.png") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-6-room {
   background: url("../../assets/images/room/6/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-7-room {
   background: url("../../assets/images/room/7/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-8-room {
   background: url("../../assets/images/room/8/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-9-room {
   background: url("../../assets/images/room/9/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
}
.bg-10-room {
   background: url("../../assets/images/room/10/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-11-room {
   background: url("../../assets/images/room/11/bg1-min.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.bg-12-room {
   background: url("../../assets/images/room/11/bg1.png") no-repeat scroll 0 0 / 100% 100%;
}
.bg-notFound {
   background: url("../../assets/images/notFound404/404_Hero.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.notFound img {
   width: 30%;
   max-width: 500px;
}
.notFoundTitle {
   max-width: 300px !important;
}
.notFound p {
   font-weight: 300 !important;
   color: #fff;
   font-size: 27px;
   padding-bottom: 28px;
   text-align: center;
   margin: 0 auto;
   font-style: italic;
}
/* AUTH PAGE */
.bg-auth {
   background: url("../../assets/images/auth/auth_background.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.logIn_subtitle img {
   width: 21% !important;
   margin-left: -0.65%;
}
.registerComplete_title img {
   width: 47%;
   padding-bottom: 45px;
}
.finalizeBTN img {
   width: 91%;
   cursor: pointer;
}
.logInBTN img {
   cursor: pointer;
   width: 25%;
   margin-left: 2.5px;
}

.registerBTN img {
   width: 104%;
   cursor: pointer;
}

.registerNowBTN img {
   width: 46.5%;
   cursor: pointer;
   margin-left: 10px;
}

@media (max-width: 991px) {
   /* .logInBTN img{
    width: 25%;
  } */
   .finalizeBTN img {
      width: 110%;
   }
}
@media (max-width: 768px) {
   .registerNowBTN img {
      width: 94%;
   }
   .missing_account {
      margin-left: 14px !important;
   }
   .logInBTN img {
      margin-left: 7px;
   }
}
@media (max-width: 485px) {
   .logInBTN img {
      width: 35%;
   }
}

/* END AUTH PAGE */

/* HOME PAGE */
.getEnergized_title img {
   width: 50%;
}
@media (max-width: 768px) {
   .getEnergized_title img {
      width: 90%;
   }
}
.video-bg {
   background: url("../../assets/images/home/Energize_container_about.webp") no-repeat scroll 0 0 / 100% 100%;
}
/* END HOME PAGE */

/* RECOVERPASSWORD PAGE */
.bg-recoverPassword {
   background: url("../../assets/images/recoverPassword/recover_password_background.svg") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
   margin-top: -1px;
}
.resetPasswordBTN img {
   width: 55%;
   cursor: pointer;
}
.backToHomeBTN img {
   width: 90% !important;
   cursor: pointer;
}
.bookNowResBTN img {
   width: 91% !important;
   cursor: pointer;
}
.recover-password {
   text-align: center;
   margin-bottom: 25px;
}
.recoverPassword_mobile {
   display: none;
}
.recoverPassword_web {
   width: 80%;
}

@media only screen and (max-width: 767px) {
   .recoverPassword_web {
      display: none;
   }

   .recoverPassword_mobile {
      display: block;
      width: 100% !important;
      margin-bottom: 20px;
   }
}

/* END RECOVERPASSWORD PAGE */

/* CHANGE EMAIL PAGE */
.bg-changeEmail {
   background: url("../../assets/images/changeEmail/Change_Email_BG_image.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}

.titleChange {
   width: 53%;
}
.titleChange-password {
   width: 65%;
}

/* END CHANGE EMAIL PAGE */

/* CHECKSCORES PAGE */
.bg-checkScores {
   background: url("../../assets/images/checkScores/Scores_Desktop.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
/* END CHECKSCORES PAGE */

/* FRANCHISE PAGE */
.bg-franchise {
   background: url("../../assets/images/franchise/franchise_background.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
   margin-top: -1px;
}
.franchise_title img {
   width: 60% !important;
}
/* END FRANCHISE PAGE */

/* PRICES PAGE */
.bg-prices {
   background: url("../../assets/images/prices/Prices_Desktop.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.price_title img {
   width: 37%;
   padding-bottom: 50px;
}

/* END PRICES PAGE */

/* BOOKING PAGE */
.booking_voucher {
   background-color: #14001a;
   padding-top: 100px;
   padding-bottom: 50px;
}
.booking_voucher img {
   width: 100%;
   max-width: 1350px;
}
.booking_mobile {
   display: none;
}

@media only screen and (max-width: 767px) {
   .booking_web {
      display: none;
   }

   .booking_mobile {
      display: block;
      width: 80% !important;
      margin: auto;
      max-width: 460px !important;
   }
   .booking_voucher {
      padding-top: 50px;
   }
}
.booking_voucher h1 {
   font-size: 40px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
}
/* END BOOKING PAGE */

/* GIFT VOUCHERS PAGE */
.bg-giftVouchers {
   /* background: url("../../assets/images/giftVouchers/Gift_Vouchers_Desktop.webp") no-repeat scroll 0 0 / 100% 100%; */
   background: url("../../assets/images/giftVouchers/Gift-Vouchers_Hero_img_Desktop.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover;
   background-position: center center !important;
   margin-top: -1px;
}
.gift_title img {
   width: 60%;
}
/* END GIFT VOUCHERS PAGE */
/* EVENTS PAGE */
.bg-events {
   /* background: url("../../assets/images/events/Corporate_Parties_Desktop.webp") no-repeat scroll 0 0 / 100% 100%; */
   background: url("../../assets/images/events/Corporate-Parties_Hero_img_Desktop.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover;
   background-position: center center;
}
.bg-events2 {
   background: url("../../assets/images/events/corporatepartiesbg2.webp") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
/* END EVENTS PAGE */

/* FAQ PAGE */
.bg-faq {
   background: url("../../assets/images/faq/FAQ_page_Desktop_backround_1.svg") no-repeat scroll 0 0 / 100% 100%;
   background-size: cover !important;
   background-position: center center !important;
}
.faq_title_text img {
   width: 100%;
}
.faq_mobile {
   display: none;
}

@media only screen and (max-width: 768px) {
   .faq_web {
      display: none;
   }

   .faq_mobile {
      display: block;
      width: 80% !important;
      margin: auto !important;
   }
}
@media only screen and (max-width: 991px) {
   .faqs .faq .faq-question::after {
      right: 0;
   }
   .faqs .faq.open .faq-answer {
      color: white !important;
   }
}

/* END FAQ PAGE */

/* ACCOUNT PAGE */
.buttonAccount img {
   width: 55% !important;
   cursor: pointer;
}
.buttonReturn img {
   width: 55% !important;
   cursor: pointer;
}
@media screen and (max-width: 768px) {
   .buttonChange img {
      width: 100% !important;
   }
}

@media screen and (max-width: 450px) {
   .buttonAccount img {
      width: 80% !important;
   }
   .buttonReturn img {
      width: 100% !important;
   }
   .buttonChange img {
      width: 100% !important;
      cursor: pointer;
   }
}

.bg-account {
   background: url("../../assets/images/account/Account_Page.webp") no-repeat 0 0 / 100% 100% !important;
   background-repeat: no-repeat !important;
   background-size: cover !important;
}

.fr_title img {
   width: 80%;
}
.account_mobile {
   display: none;
}
@media only screen and (max-width: 500px) {
   .account_web {
      display: none;
   }

   .account_mobile {
      display: block;
      margin-left: 10%;
   }
}
/* END ACCOUNT PAGE */

.red {
   box-shadow: 0 0 10px 5px rgb(219 27 23) !important;
   border: 2px solid #fff;
   background: url("../../assets/images/games/bg3.png") no-repeat scroll 0 0 / 100% 100% !important;
}

.green {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2 205 62) !important;
   background: linear-gradient(to top, #01ab34, #00d942) !important;
}

.buttonLogin {
   font-family: "sui-generis";
   color: #fff;
   width: 194px;
   height: 64px;
   font-size: 35px;
   font-weight: 700;
   cursor: pointer;
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2 205 62);
   background: linear-gradient(to top, #01ab34, #00d942);
}

.buttonRegister {
   font-family: "sui-generis";
   color: #fff;
   width: 320px;
   height: 64px;
   font-size: 35px;
   font-weight: 700;
   cursor: pointer;
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2 205 62);
   background: linear-gradient(to top, #01ab34, #00d942);
}

.buttonLoggedIn {
   font-family: "sui-generis";
   color: #fff;
   width: 520px;
   height: 64px;
   font-size: 35px;
   font-weight: 700;
   cursor: pointer;
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2 205 62);
   background: linear-gradient(to top, #01ab34, #00d942);
}

.games_content_green {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   background: linear-gradient(to top, rgb(113, 21, 8, 0), rgb(0, 216, 65, 0.5));
   margin-left: 30px;
}
.games_content_red {
   border: 2px solid #fff;
   box-shadow: 0 0 10px 5px rgb(219 27 23) !important;
   background: rgba(219, 27, 23, 0.3);
   margin-left: 30px;
}

.error-message {
   color: #fe0000;
   font-size: 20px;
   font-weight: 700;
   font-family: "sui-generis";
   margin-top: 20px;
}

/* .error-message{
  color: #fe0000;
  font-size: 2.8vw;
  font-weight: 300;
  margin-top: 1vw;
  position: absolute;
} */

.is-invalid {
   border: 2px solid #fff !important;
   box-shadow: 0 0 10px 5px rgb(219 27 23) !important;
   background: rgba(219, 27, 23, 0.3) !important;
}

.is-valid {
   /* background-color: rgba(0,181,55,.25)!important;
  box-shadow: inset 0 0 3vw #00b537, 0 0 3vw #00b537;
  border: 1px solid #839D71; */
}

.modal-content {
   background-color: rgba(25, 126, 56, 0.8);
   border: 3px solid #fff;
   border-radius: 0;
   box-shadow: inset 0 0 2vw #00e646, 0 0 2vw #00e646;
   color: #fff;
   font-size: 3vw;
   font-style: italic;
   padding-right: 6vw;
}

.image-modal {
   left: -3vw;
   position: relative;
   width: 32vw;
}

.form-check {
   margin-bottom: 0.125rem;
   min-height: 1.5rem;
   padding-left: 1.5em;
}

.form-check input {
   /* box-shadow: inset 0 0 2vw #00b537, 0 0 2vw #00b537;
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(33, 148, 64, 0.7) 100%);
  border: 1px solid rgba(0,0,0,.25);
  border-radius: 0.1em;
  margin-left: -0.75vw;
  
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  vertical-align: top;
  float: none;
 
  height: 2em;
  width: 2em;
  margin-top: 0.25em; */
}
.checkbox-style {
   width: 4em;
   height: 4em;
   margin-left: 1em;
   margin-right: 1em;

   border-radius: 0.1em;
   -webkit-appearance: none;
   appearance: none;

   -webkit-print-color-adjust: exact;
   print-color-adjust: exact;
   vertical-align: top;
   float: none;
   align-self: center;
}
.checkbox-is-invalid {
   background-color: rgba(254, 0, 0, 0.25) !important;
   box-shadow: inset 0 0 0.8vw #fe0000, 0 0 0.8vw #fe0000 !important;
   background-image: none !important;
   cursor: pointer;
}

.checkbox-is-valid {
   background: url("../images/auth/checkbox_nebifat-min.webp") no-repeat 52% 51%/118% 114% !important;
   background-size: cover;
   cursor: pointer;
}

.checkbox-is-valid:checked {
   background: url("../images/auth/checkbox_bifat-min.webp") no-repeat 50% 52%/110% 109% !important;
   width: 3em;
   height: 3em;
   box-shadow: none;
   /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E"); */
}

.link {
   color: #fff;
   font-size: 20px;
   font-weight: 300;
   text-decoration: underline;
   text-decoration-thickness: 0.1px;
   font-style: italic;
   align-self: center;
}

.faqs {
   width: 100%;
   /* max-width: 768px; */
   margin: 0 auto;
   padding: 15px;
}

.faqs .faq {
   margin: 15px;
   padding: 15px;
   color: white;
   background-color: transparent;

   border-bottom: 1px solid #91a47e;
}

.faqs .faq .faq-question {
   text-transform: uppercase;
   position: relative;
   font-size: 22px;
   font-weight: 500;
   padding-right: 80px;
   transition: all 0.4s ease;
   cursor: pointer;
}

.faqs .faq .faq-question::after {
   content: "";
   position: absolute;
   /* top: 50%;
	transform: translateY(-50%); */
   width: 30px;
   height: 30px;
   background-image: url("../images/faq/faq_plus_icon.svg");
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
   opacity: 0;
   max-height: 0;
   overflow-y: hidden;
   transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
   margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
   background-image: url("../images/faq/faq_minus_icon.svg");
}

.faqs .faq.open .faq-answer {
   max-height: 1000px;
   opacity: 1;
   color: #91a47e;
   white-space: pre-line;
   vertical-align: bottom;
}

.single_fra_input select {
   width: 100%;
   outline: none;
   background: transparent;
   font-size: 16px;
   font-weight: 400;
   color: #fff;
   border: 1px solid #839d71;
   padding: 15px 22px;
   height: 60px;
   font-family: "sui-generis";
}
.single_fra_input select option {
   background-color: rgb(207, 243, 177);
   color: #000;
   text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.single_fra_input select option:hover {
   background-color: #000 !important;
}

.faq_title {
   margin-bottom: 40px;
   padding-bottom: 40px;
}
.faq_title h1 {
   font-size: 50px;
   font-weight: 700;
   font-style: italic;
   text-transform: uppercase;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   text-align: center;
}

.event_subtitle h3 {
   font-size: 32px;
   font-weight: 550;
   font-style: italic;
   text-transform: capitalize;
   color: #fff;
   margin-bottom: 26px;
   max-width: 500px;
   text-align: center;
   margin: 0 auto;
   margin-top: 10px;
}

.event_title h1 {
   font-size: 38px;
   font-weight: 700;
   font-style: italic;
   text-transform: uppercase;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   text-align: center;
}

.event_title h2 {
   font-size: 39px;
   font-weight: 550;
   font-style: italic;
   text-transform: capitalize;
   color: #fff;
   margin-bottom: 26px;
   text-align: center;
}

.event_title p {
   font-size: 16px;
   padding-top: 2%;
   color: #fff;
   text-align: center;
   max-width: 955px;
   margin: 0 auto;
   font-size: 15;
   font-weight: 400;
   margin-top: 47px;
   width: 100%;
}

.event_icon_area h2 {
   color: #fff;
   text-transform: capitalize;
   margin-bottom: 25px;
   font-style: italic;
   font-weight: 500;
}

.event_icon_area p {
   color: #91a47e;
   padding-bottom: 20px;
}

.icons_area_events {
   display: flex;
}

.icons_area_align {
   display: flex;
   align-items: center;
   padding-top: 40px;
}

.single_item_events {
   text-align: center;
}

.single_item_events p {
   color: #fff;
   margin-bottom: 9px;
   font-size: 15px;
   font-weight: 550;
   text-transform: uppercase;
}
.s_img_events img {
   height: 115px;
   margin-bottom: 8%;
}

.btn-events {
   display: flex;
   justify-content: center;
   padding-top: 50px;
}

.FadeAway {
   background: transparent;
   background: linear-gradient(to top, rgb(35, 1, 40) 19%, transparent, rgba(63, 15, 68) 113%);
}

.event_area_design {
   position: relative;
   z-index: 1;
}

.event_area_design:after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: -1;
   background-color: rgba(13, 5, 17, 0.6);
}

.gift_title h1 {
   font-size: 38px;
   font-weight: 700;
   font-style: italic;
   text-transform: uppercase;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   padding-left: 3%;
   text-align: left;
}

.gift_title h2 {
   padding-top: 90px;
   font-size: 27px;
   font-weight: 550;
   font-style: italic;
   text-transform: uppercase;
   color: #fff;
   margin-bottom: 26px;
   padding-left: 3%;
   text-align: left;
}

.gift_title p {
   font-size: 16px;
   color: #fff;
   padding-left: 3.5%;
   text-align: left;
   max-width: 955px;
   font-size: 15;
   font-weight: 400;
   width: 100%;
}

.btn-gifts {
   display: flex;
   justify-content: flex-start;
   padding-left: 3%;
   padding-top: 30px;
}
.recover_password_title {
   margin-bottom: 40px;
   padding-bottom: 40px;
}
.recover_password_title h1 {
   font-size: 61px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   text-align: center;
   padding-bottom: 20px;
}
.recover_password_title p {
   color: #fff;
   font-size: 21px;
   font-weight: 300;
   max-width: 840px;
   text-align: center;
   margin: 0 auto;
   margin-top: 54px;
   margin-bottom: 40px;
   font-style: italic;
}

.register_complete_title {
   margin-bottom: 40px;
}
.register_complete_title h1 {
   font-size: 61px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 26px;
   text-align: center;
}
.register_complete_title p {
   color: #fff;
   font-size: 20px;
   font-weight: 300;
   max-width: 840px;
   text-align: center;
   margin: 0 auto;
   margin-bottom: 100px;
   font-style: italic;
}

.menu_nickname {
   font-size: 10px !important;
   font-weight: 100 !important;
   color: #00e646 !important;
}
.menu_nickname li {
   font-size: 20px !important;
   font-weight: 300 !important;
   text-transform: none !important;
}

.image_modal {
   position: relative;
   width: 200px;
}

/* modal scrollbar */
div::-webkit-scrollbar-track {
   box-shadow: inset 2 0 10px #f8fbff;
   border-radius: 5px;
}
div::-webkit-scrollbar-thumb {
   background-color: #fff;
   border-radius: 5px;
}
div::-webkit-scrollbar {
   width: 0.4em;
   border-radius: 5px;
}

/* React modal npm */
.ReactModal__Overlay--after-open {
   background-color: transparent !important;
   z-index: 200 !important;
   background-image: url("../images/auth/auth_background.webp") !important;
   background-repeat: no-repeat !important;
   background-size: cover !important;
}
.ReactModal__Content {
   overflow: hidden !important;
   padding-left: 0 !important;
}
.overrideScroll {
   overflow: auto !important;
   background-image: url("../images/locations/Locations_Page_BG.webp") !important;
}
.pin-modal-background {
   position: absolute;
   width: 100%;
   height: 100%;
}

.pin-modal-background img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

/* React modal-video npm */
.modal-video {
   position: absolute !important;
}

.modal-header {
   align-items: center;
   border-top-left-radius: 0.49rem;
   border-top-right-radius: 0.49rem;
   display: flex;
   flex-shrink: 0;
   justify-content: space-between;
   padding: 1rem;
   border-bottom: 0;
}
.modal-title {
   line-height: 1.5;
   margin-bottom: 0;
}
.modal-body {
   height: 73vh;
   margin-bottom: 5vw;
   margin-top: 2vw;
   overflow-y: scroll;
   padding: 0vw 2vw 0vw;
}

.missing_account {
   color: #cff3b1 !important;
   font-weight: 300;
   font-style: italic;
   margin-left: 16px;
}

.forgot_password {
   color: white !important;
   font-weight: 400;
   font-style: italic;
   text-decoration-line: underline !important;
   text-decoration-thickness: 0.1px !important;
   text-decoration-color: #918a92 !important;
   cursor: pointer;
   margin-left: 12px;
}

.fa.fa-map-marker-menu {
   font-size: 28px;
}
.row_menu {
   margin-left: 0;
}

.iframe-class {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 100%;
}

.testWrapper {
   position: relative;
   padding-bottom: 60%;
   padding-top: 25px;
}
.testWrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.price_title h1 {
   font-size: 38px;
   font-weight: 700;
   font-style: italic;
   text-transform: uppercase;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-bottom: 50px;
   padding-left: 3%;
   text-align: center;
}

.price_week-days {
   background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgba(4, 112, 38, 1));
   padding: 10px;
   color: white;
   text-align: center;
   justify-content: center;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
}

.price_week-days h2 {
   text-transform: uppercase;
   font-size: 18px;
   font-weight: 500;
}

.price_hours-left {
   background-color: #520052;
   padding: 10px;
   color: white;
   text-align: center;
   border-top-left-radius: 20px;
   margin: auto;
}
.price_hours-left img {
   align-self: center;
   width: 28px;
}

.price_hours-left h3 {
   font-size: 17.5px;
   font-weight: 400;
   align-self: center;
   margin: auto;
}

.price_hours_container-top {
   border: #00e646 1.5px solid;
}
.price_hours_container-left {
   border: #00e646 1.5px solid;
}
.price_hours_container-right {
   border: #00e646 1.5px solid;
}
.price_hours_container {
   border: #00e646 1.5px solid;
}

.price_hours_container-top h3 {
   font-size: 14.5px;
   font-weight: 400;
   color: #808080;
   text-align: center;
}
.price_hours_container-top h2 {
   font-size: 22px;
   font-weight: 400;
   color: white;
   text-align: center;
   margin-top: 8px;
   margin-bottom: 7px;
}

.price_hours_container-left h2 {
   font-size: 20px;
   font-weight: 400;
   color: white;
   text-align: center;
   margin-top: 8px;
   margin-bottom: 7px;
}
.price_hours_container-left h3 {
   font-size: 14.5px;
   font-weight: 400;
   color: #808080;
   text-align: center;
}

.price_hours_container-right h2 {
   font-size: 20px;
   font-weight: 400;
   color: white;
   text-align: center;
   margin-top: 8px;
   margin-bottom: 7px;
}
.price_hours_container-right h3 {
   font-size: 14.5px;
   font-weight: 400;
   color: #808080;
   text-align: center;
}

.price_hours_container h3 {
   font-size: 14.5px;
   font-weight: 400;
   color: #808080;
   text-align: center;
}
.price_hours_container h2 {
   font-size: 20px;
   font-weight: 400;
   color: white;
   text-align: center;
   margin-top: 8px;
   margin-bottom: 7px;
}

.price_hours-right {
   background-color: #520052;
   padding: 10px;
   color: white;
   text-align: center;
   border-top-right-radius: 20px;
   margin: auto;
}
.price_hours-right img {
   align-self: center;
   width: 28px;
}

.price_hours-right h3 {
   font-size: 17.5px;
   font-weight: 400;
   align-self: center;
   margin: auto;
}

.price_weekend-days {
   background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgba(4, 112, 38, 1));
   padding: 10px;
   color: white;
   text-align: center;
   justify-content: center;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
}

.price_weekend-days h2 {
   text-transform: uppercase;
   font-size: 18px;
   font-weight: 500;
}

.price_hours {
   background-color: #520052;
   padding: 10px;
   color: white;
   text-align: center;
   border-top-right-radius: 20px;
   border-top-left-radius: 20px;
   margin: auto;
}
.price_hours img {
   align-self: center;
   width: 28px;
}

.price_hours h3 {
   font-size: 17.5px;
   font-weight: 400;
   align-self: center;
   margin: auto;
}

.button_book_now {
   display: inline-block;
   position: relative;
   z-index: 100;
}

.button_book_now a {
   color: #fff !important;
   text-transform: uppercase;
   font-weight: 500;
   letter-spacing: 1px;
   background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(33, 148, 64, 0.7) 100%);
   padding: 7px 37px;
   display: inline-block;
   font-size: 36px;
   position: relative;
   transition: color 1s;
}

.button_book_now:hover a {
   color: #00b537;
   background-size: 104% 150%;
   background-position: 50% 50%;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
   z-index: 1;
   color: #00ff00 !important;
   cursor: pointer;
}

.button_book_now a::after,
.button_book_now a::before {
   content: "";
   position: absolute;
   height: 100%;
   width: 6px;
   background: #fff;
   top: 0;
   border: 1.5px solid #10d83a;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}

.button_book_now:hover:before {
   transition: width 1s;
   width: 99%;
}

.button_book_now:hover:after {
   transition: width 1s;
   width: 99%;
   z-index: 1;
}

.button_book_now a::after {
   right: 0;
}

.button_book_now a::before {
   left: 0;
}
.button_book_now:after {
   right: 0;
   top: 0;
}

.button_book_now:before {
   left: 0;
   bottom: 0;
}

.button_book_now:after,
.button_book_now:before {
   content: "";
   position: absolute;
   height: 5px;
   width: 78px;
   border: 1px solid #10d83a;
   background-color: #fff;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}
.btn_events a {
   font-size: 21px;
}

.modal-location-header {
   align-items: center;
   border-top-left-radius: 0.49rem;
   border-top-right-radius: 0.49rem;
   display: flex;
   flex-shrink: 0;
   justify-content: space-between;
   padding: 1rem;
   border-bottom: 0;
}
.modal-location-title {
   line-height: 1.5;
   margin-bottom: 0;
}
.modal-location-body {
   height: 73vh;
   margin-bottom: 5vw;
   margin-top: 2vw;
   overflow-y: scroll;
   padding: 0vw 2vw 5.5vw;
}
.modal-location-image {
   position: relative;
   width: auto;
   height: 220px;
}
.modal-location-title h2 {
   text-decoration: underline;
   text-decoration-thickness: 1px;
   font-size: 32px;
   font-weight: 600;
   padding-bottom: 5px;
   text-transform: uppercase;
}
.modal-location-title h3 {
   font-weight: 400;
   padding-bottom: 6px;
   font-size: 25px;
}
.modal-location-title h3:hover {
   color: #e8e8e8;
   text-shadow: 5px 5px 13px #10d83a;
   -webkit-text-stroke: 0.4px #10d83a;
   text-shadow: 0 0 7px #10d83a, 0 0 10px #10d83a, 0 0 21px #10d83a, 0 0 42px #10d83a, 0 0 82px #10d83a, 0 0 92px #10d83a, 0 0 102px #10d83a,
      0 0 151px #10d83a;
   cursor: pointer;
}

.modal-location-icons {
   padding-top: 100px;
   padding-bottom: 30px;
}
.modal-location-icons img {
   width: 48px;
}
.modal-location-icons a {
   color: #fff;
   font-size: 50px;
   margin-left: 10px;
   margin-right: 10px;
   transition: 0.3s;
   justify-content: space-between;
}

.modal-location-icons a:hover {
   opacity: 0.8;
}
.modal-location-copyright p,
.modal-location-copyright p a {
   color: #cff3b1 !important;
   font-weight: 300;
   font-style: normal !important;
   text-align: center;
   font-size: 15px;
}
.modal-location-all {
   padding-top: 75px;
}

.create_shap_green {
   position: absolute;
   width: 100%;
   height: 63px;
   border: 1px solid #10d83a;
   background-color: #fff;
   box-shadow: 0px 0px 11px 3px rgb(16, 216, 58);
}

.create_shap_green {
   position: absolute;
   width: 100%;
   height: 63px;
   border: 1px solid #291229;
   background-color: rgba(255, 255, 255, 0.9);
   box-shadow: 0px 0px 15px 6px rgb(80, 39, 80);
   left: -12%;
   right: 0;
   bottom: -3px;
   transform: rotate(1deg);
   z-index: 999999;
}
.check_scores_title h1 {
   color: #fff;
   font-size: 50px;
   font-weight: 700;
   font-style: italic;
   text-shadow: #000 0px 0px 10px;
   margin-bottom: 30px;
}
.check_scores_title p {
   color: #fff;
   font-size: 15px;
   margin-bottom: 50px;
}

.menu_text {
   color: #fff;
   font-weight: 500;
   font-size: 20px;
   text-align: center;
   text-transform: uppercase;
   margin: 0 25px;
   align-self: center;
}
.user_icon img {
   height: 30px;
   align-self: center;
   position: inherit !important;
   margin-top: 0 !important;
   margin-left: -10px !important;
}

.subtitle_text {
   margin-top: 13px !important;
}
.or_text {
   font-weight: 300 !important;
   color: #fff;
   font-size: 27px;
   max-width: 500px;
   text-align: center;
   margin: 0 auto;
   margin-top: 12px !important;
   margin-bottom: 0px !important;
   font-style: italic;
}
.prices_icons img {
   width: 70%;
   padding-top: 16px;
   padding-bottom: 5px;
}
.prices_icons2 img {
   width: 29%;
   padding-top: 12.7px;
   padding-bottom: 3.7px;
}
.prices_icons_single img {
   width: 36%;
   padding-top: 12.7px;
   padding-bottom: 7.7px;
}
.boxshadow2 {
   box-shadow: inset 0 32px 6px -4px rgb(23 10 39 / 80%);
}
.p_line_event_purp {
   width: 200px;
   height: 3px;
   position: absolute;
   border-radius: 50px;
   background-color: #fff;
   box-shadow: 0px 0px 15px 6px rgb(195 63 195);
   z-index: 1;
}
.p_line_event_purp.p_line_event_purp1 {
   left: 10%;
   top: 1%;
   /* width: 15%; */
}
.p_line_event_purp.p_line_event_purp2 {
   left: 5%;
   top: 4%;
}

.p_line_event_green {
   width: 200px;
   height: 3px;
   background-color: #fff;
   box-shadow: 0 0 10px 5px rgb(2, 205, 62);
   position: absolute;
   border-radius: 50px;
}
.p_line_event_green.p_line_event_green1 {
   right: 12%;
}
.p_line_event_green.p_line_event_green2 {
   right: 6%;
   margin-top: 15px;
}
.p_line_event_green3 {
   right: 45%;
   top: 0;
}
.cursor_p {
   cursor: pointer;
}
.iframe_home {
   width: 86%;
   height: 324px;
   border: 0;
}
.iframe_home2 {
   width: 87.2%;
   height: 331px;
   margin-left: 4px;
   border: 0;
   object-fit: cover;
}
.iframe_footer {
   width: 92.7%;
   height: 324px;
   border: 0;
}
.map-bg {
   background: url("../../assets/images/footer/map_edges.webp") no-repeat scroll 0 0 / 100% 99.7%;
}
.footer_area-map {
   max-width: 950px;
   margin: 0 auto;
   min-height: 365px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.checkbox-left {
   margin-left: -12px;
}

.container_coming_soon {
   background-image: url("../../assets/images/coming_soon_bg.webp");
   background-size: cover;
   height: 100vh;
}
.container_coming_soon img {
   width: 60%;
}
.container_coming_soon h1 {
   width: auto;
   text-align: center;
   font-weight: 600;
   font-size: 100px;
   font-weight: 700;
   font-style: italic;
   color: #fff;
   -webkit-text-stroke: 2px #10d83a;
   text-shadow: 0px -2px 13px #10d83a;
   margin-top: 100px;
   -webkit-text-stroke: 0.4px #10d83a;
}

.w-img {
   width: 75%;
   position: relative;
   /* left: -2.8vw; */
}

.w-img2 {
   position: relative;
   width: 16.5%;
}
.width100 {
   width: 100%;
}

.get_social_title h2 {
   font-size: 26px;
}

.gift-voucher-btn {
   font-size: 21.5px !important;
}
.button-search-score {
   font-size: 21.5px !important;
   padding-top: 11.5px !important;
   padding-bottom: 11.5px !important;
   padding-left: 5.15rem !important;
   padding-right: 5.15rem !important;
}
.color-white {
   color: white;
}
.no-active-green {
   color: #cff3b1 !important;
   font-weight: 400 !important;
}
.no-active-red {
   color: #ffbfbf !important;
   font-weight: 400 !important;
}
.button-achivement {
   margin-top: 2%;
   margin-left: 3%;
}
.earn-more-energize {
   font-size: 18px !important;
   padding-top: 14px !important;
   padding-bottom: 14px !important;
   padding-left: 2rem !important;
   padding-right: 2rem !important;
}
.table td,
.table th {
   padding: 0.3rem !important;
}

.coop_name_h3 {
   color: #cff3b1 !important;
}
.comp_name_h3 {
   color: #ffbfbf !important;
}

.top_rank_right2 h3 {
   color: white;
   font-size: 20px;
   text-align: center;
}
.top_rank_right2 h4 {
   color: white;
   font-size: 20px;
   text-align: center;
   font-weight: 300;
}
.password-reset img {
   width: 55%;
}
.account-page-btn {
   text-transform: capitalize !important;
   font-style: italic;
   font-size: 1.4vw !important;
}
.second-change {
   padding-left: 4vw !important;
   padding-right: 4vw !important;
}
.book-now-home {
   font-size: 26px !important;
}
.submit-event {
   font-size: 20px !important;
}
.p-critical {
   margin-bottom: 22px !important;
   margin-top: 9px !important;
}
.color_ffbfbf {
   color: #ffbfbf !important;
}
.color_cff3b1 {
   color: #cff3b1 !important;
}
.first-change {
   display: flex;
   justify-content: end;
}
.second-change {
   display: flex;
}
.table-rankings {
   color: white;
   border-spacing: 10px 0;
   border-collapse: separate;
}
.table-rankings thead tr th:nth-child(1) {
   text-align: center;
}
.table-rankings td:nth-child(1) {
   text-align: center;
}
.table-rankings thead tr th {
   font-weight: 400;
   font-size: 16px;
}
.table-rankings tbody tr {
   font-weight: 700;
   font-size: 16.5px;
}
.table-rankings th {
   border-top: 0px !important;
}
.table-rankings td {
   border-top: 0px !important;
   padding-left: 12px !important;
}
.table-rankings td:nth-child(1) {
   padding-left: 0px !important;
}
.table-rankings thead tr th:nth-child(2) {
   padding-left: 12px !important;
}
.table-rankings thead tr th:nth-child(3) {
   padding-left: 12px !important;
}
.rank-col {
   background: url(../images/games/gp.png) no-repeat scroll 0 0 / 100% 100%;
}

.text-uppercase {
   text-transform: uppercase;
}
.p-about {
   margin-top: 25px !important;
}
.cff3b1 {
   color: #cff3b1 !important;
}
.ffbfbf {
   color: #ffbfbf !important;
}
.resova-container {
   background: #14001a !important;
}
.trailer-modal {
   position: relative;
   z-index: 1000;
   top: 0;
   bottom: 0;
}
.trailer-modal-video {
   width: 80%;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 500;
}
.trailer-modal-video-background {
   position: fixed;
   inset: 0;
   background-color: rgba(0, 0, 0, 0.7);
   z-index: 500;
}

.button-test {
   position: fixed;
   inset: 0;
   z-index: 500;
}

.prices-student-description {
   font-size: 18px;
   color: #fff;
   font-weight: 400;
   font-style: italic;
   max-width: 85%;
   text-align: center;
   margin: auto;
   margin-top: 45px;
}

.disabled-sidebar {
   /* pointer-events: none; */
   cursor: not-allowed !important;
}

.red-glow {
   background: linear-gradient(to top, #8d2c35, #f90808) !important;
}
.no-red-glow {
   background: url(../images/games/bg3.png) no-repeat scroll 0 0 / 100% 100% !important;
}
.button-no-style {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
